@charset "utf-8";

body,fieldset,form,h1,h2,h3,h4,h5,h6,dd,dl,dt,th,ul,li,ol,p,div,input,legend,select,table,td,textarea,button{margin:0;padding:0;}
body,html{color:#333;font-family: 'Noto Sans KR', sans-serif; font-size:16px;line-height: 1.5;}
* {outline: none !important}
input,textarea,select,button,table,th,td,pre{color:#333;font-family: 'Noto Sans KR', sans-serif; font-size: 0.875rem;line-height:1.5;outline: none}
h1,h2,h3,h4,h5,h6{line-height:1.2; font-family: 'Noto Sans KR', sans-serif; }
img,fieldset,iframe{border:0;vertical-align:top}
textarea{resize:none}
ul,ol{list-style:none}
ul:after,ol:after{content:'';display:block;clear:both}
input,select{vertical-align:middle}
table{width:100%;border-collapse:collapse;font-size:inherit;text-align:left;border-spacing:0}
td,th{margin:0;padding:0;word-break:keep-all;word-wrap:break-word}
hr,legend{display:none}
caption{overflow:hidden;position:absolute;top:0;left:0;width:0;height:0;font-size:0;line-height:0}
button{box-sizing:border-box;border:none;background:none;cursor:pointer}
a{color:inherit;text-decoration:none;box-sizing:border-box;}
a:hover{text-decoration:none}

em { font-style: normal; }