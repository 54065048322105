@charset "utf-8";


/* basic */
html { height: 100%; }
body { font-family: 'Noto Sans KR', sans-serif; word-break: keep-all; background: #fff; }
body * { -webkit-box-sizing: border-box; box-sizing: border-box; }
a:hover { color: initial; }


/* common */
.block { display: block !important; }
.inline-b > * { display: inline-block; }
.m-visible { display: none; }
.hidden { font-size: 0; line-height: 0; position: absolute; left: -9999px; width: 0; height: 0; overflow: hidden; }
.underline { text-decoration: underline; }
.underline:hover { text-decoration: underline; color: #333; }
.absolute-r01 { position: absolute; top: 20px; right: 20px; }
.absolute-r02 { position: absolute; top: 20px; right: 0; }
.absolute-r03 { position: absolute; top: 0; right: 0; }
.absolute-r04 { position: absolute; top: -10px; right: 0; }
.bd-none { border: 0 none !important; }
.bd-bottom { border-bottom: 1px solid #ddd; }
.ellipsis01 { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 90%; }
.wrap-img img { width: 100%; height: auto; }
.wrap { position: relative; }
.text-l { display: block; text-align: left; }
.text-r { display: block; text-align: right; }
.text-center { text-align: center; }

.col-02,
.col-03,
.col-04 { width: 100%; overflow: hidden; }
.col-02 li { width: 50%; float: left; }
.col-03 li { width: 33.3%; float: left; }
.col-04 li { width: 25%; float: left; }

.h-100 { height: 100vh; }

.w-10 { width: 10% !important; }
.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-40 { width: 40% !important; }
.w-50 { width: 50% !important; }
.w-60 { width: 60% !important; }
.w-70 { width: 70% !important; }
.w-80 { width: 80% !important; }
.w-90 { width: 90% !important; }
.w-100 { width: 100% !important; }

.font-small { font-size: 0.75rem; }
.txt-small { font-size: 0.75rem !important; }

.wrap-img img { width: 100%; height: auto; }


/* space */
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 0.625rem !important; }
.mt-15 { margin-top: 0.938rem !important; }
.mt-20 { margin-top: 1.250rem !important; }
.mt-30 { margin-top: 1.875rem !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-100 { margin-top: 6.250rem !important; }

.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 0.625rem !important; }
.mb-15 { margin-bottom: 0.938rem !important; }
.mb-20 { margin-bottom: 1.250rem !important; }
.mb-30 { margin-bottom: 1.875rem !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-100 { margin-bottom: 6.250rem !important; }

.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 0.625rem !important; }
.ml-20 { margin-left: 1.25rem !important; }

.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 0.625rem !important; }

.pt-0 { padding-top: 0 !important; }
.pt-5 { padding-top: 5px!important; }
.pt-10 { padding-top: 0.625rem !important; }
.pt-15 { padding-top: 0.938rem !important; }
.pt-20 { padding-top: 1.250rem !important; }
.pt-25 { padding-top: 25px !important; }
.pt-30 { padding-top: 1.875rem !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-100 { padding-top: 6.250rem !important; }

.pb-0 { padding-bottom: 0 !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 0.625rem !important; }
.pb-15 { padding-bottom: 0.938rem !important; }
.pb-20 { padding-bottom: 1.250rem !important; }
.pb-30 { padding-bottom: 1.875rem !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-100 { padding-bottom: 6.250rem !important; }

.pr-0 { padding-right: 0 !important; }
.pr-5 { padding-right: 5px!important; }
.pr-10 { padding-right: 0.625rem !important; }
.pr-20 { padding-right: 1.25rem !important; }

.pl-0 { padding-left: 0 !important; }
.pl-5 { padding-left: 5px!important; }
.pl-10 { padding-left: 0.625rem !important; }
.pl-20 { padding-left: 1.25rem !important; }

.pd-5 { padding: 5px !important}
.pd-10 { padding: 10px !important}


/* color */
.primary { color: #588ed4; }
.red { color: #ff0000; }
.point { color: #588ed4; }
.blue { color: #0086ff; }


/* title */
.tit01 { font-size: 2rem; color: #1255aa; font-weight: 300; text-align: center; }
.tit01 em { color: #588ed4; }
.tit02 { display: block; margin-bottom: 0.625rem; font-size: 1.5rem; font-weight: 400; }
.tit03 { margin-bottom: 0.625rem; padding-bottom: 0.938rem; font-size: 1rem; text-align: center; color: #588ed4; border-bottom: 0.250rem solid #a8d6fd; }
.tit04 { margin-bottom: 0.937rem; font-size: 1.125rem; font-weight: 300; }
.tit04 b { font-weight: bold; }
.tit05 { margin-bottom: 0.937rem; font-size: 1.25rem; font-weight: 600; }
.tit06 { margin-bottom: 0.937rem; font-size: 0.995rem; font-weight: 300; }
.tit06 b { font-weight: bold; }

/* text */
.txt01 { margin: 1.25rem 0 0.6rem; font-size: 1.625rem; text-align: center; font-weight: 600; word-break: keep-all; }
.txt02 { font-size: 0.875rem; line-height: 1.125rem; }
.txt02 em { color: #bbb; }
/* .txt02 > .menuListbox {display: grid; grid-template-columns: 33% 33% 33%; width: 100%; row-gap : 15px;} */
.txt02 > .menuListbox {display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; width: 100%; row-gap : 15px;}
.txt02 > .menuListbox > .menuEditbox {display: flex;justify-content: center;align-items: center;}
.txt02 > .menuListbox > .menuEditbox > .form-control {width: 100%; margin: 10px 0;}
.txt02 > .menuListbox > .input-group { width: 95%;}
.txt02 > .optionListBox { width: 100%;display: flex;flex-direction: row;flex-wrap: wrap;}
.txt02 > .optionListBox > .option {margin-bottom:15px; margin-right:15px;}
.txt02 > .tabBox { width: 100%;display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;}
.txt02 > .tabBox > .tab {margin-bottom:15px; margin-right:15px;}
.txt02 a { color: #588ed4; }
.txt03 { font-size: 1.625rem; line-height: 2.250rem; text-align: center; font-weight: 600; }
.txt04 { font-size: 1.75rem; }
.txt04 b { color: #588ed4; }
.txt05 { color: #888; }
.txt06 { color: #959aaa; font-size: 0.875rem; line-height: 2.125rem;}
.txt07 { display: block; font-size: 1.875rem; }
.txt08 { color: #588ed4; }
.txt09 { font-size: 0.875rem; line-height: 1.125rem; }
.txt10 { padding:5px 5px 8px 0; margin-right:10px; border-radius: 6px; color: #588ed4; line-height: 1.125rem; }
.txt11 { color : #0e0e0e;}

/* line */
.bd-t { border-top: 1px solid #eee; }


/* bullet */
.bullet { position: relative; }
.bullet:before { display: block; content: ""; position: absolute; left: 0; width: 0.250rem; height: 0.250rem; background: #011627;}
.bullet.type01:before { top: 5px; margin-top: -2px; -webkit-border-radius: 30%; border-radius: 30%; }
.bl-list li { position: relative; }
.bl-list li:before { display: block; content: ""; position: absolute; left: 0; }
.bl-list.type01 li { margin-top: 5px; padding-left: 8px; color: #959aaa; font-size: 0.75rem; }
.bl-list.type01 li:first-child {margin-top: 0; }
.bl-list.type01 li:before { top: 7px; margin-top: -1px; -webkit-border-radius: 50%; border-radius: 50%;background: #959aaa; width: 2px; height: 2px; }
.bl-list.type02 li { padding-left: 8px; margin-top: 5px; font-size: 0.75rem; }
.bl-list.type02 li:first-child {margin-top: 0; }
.bl-list.type02 li:before { top: 7px; margin-top: -1px; -webkit-border-radius: 50%; border-radius: 50%;background: #333; width: 2px; height: 2px; }
.bl-list.type03 li { padding-left: 8px; margin-top: 5px; }
.bl-list.type03 li:first-child {margin-top: 0; }
.bl-list.type03 li:before { top: 11px; margin-top: -1px; -webkit-border-radius: 50%; border-radius: 50%;background: #333; width: 2px; height: 2px; }


/* list */
.list01 li { display: inline-block; margin-right: 0.625rem; }
.list02 li { border-top: 1px solid #ddd; }
.list02 li:first-child { border-top: 0 none; }
.list03 { width: 102%; position: relative; left: -0.313em; }
.list03 li { padding: 0.313rem; }
.list04 li { padding-left: 20px;  border-left: 1px solid #ddd; }
.list04 li:first-child { padding-left: 0; border-left: 0 none; }
.list-scroll { padding: 10px; }
.list-scroll ul { width: 100%; overflow-y: auto; }
.list-scroll.type01 { border: 1px solid #ddd; }
.list-scroll.type01 ul { height: 300px; }
.list-scroll.type01 li { margin-bottom: 5px; }
.list-scroll.type01 li:nth-child(even) { background-color:#cde1ff; }


/* dl */
.dl01 dt { font-weight: 600; margin-bottom: 10px; }
.dl02 { width: 100%; overflow: hidden; }
.dl02 dt { width: 100px; float: left; }
.dl02 dd { float: left; }
.wrap-dl03 { padding: 10px 0; border-top: 1px solid #000; }
.dl03 { margin-bottom: 5px; width: 100%; overflow: hidden; }
.dl03 dt { width: 30%; float: left; }
.dl03 dd { width: 70%; float: left; }
.wrap-dl04 { padding: 10px; border: 1px solid #ede69f; background: #fefef5; -webkit-border-radius: 0.25rem; border-radius: 0.25rem;}
.dl04 { margin-top: 5px; width: 100%; overflow: hidden; }
.dl04:first-child { margin-top: 0; }
.dl04 dt { float: left; font-size: 11px; color: #e94807; }
.dl04 dd { float: left; font-size: 11px; }
.dl05 { height: 240px; }
.dl05 dt { margin-bottom: 10px; }
.wrap-dl05 dl { margin-bottom: 20px; }
.wrap-dl05 dt { margin-bottom: 10px;  font-weight: bold; font-size: 0.75rem; color: #959aaa; margin: 0; position: absolute; top: 0; left: 0; font-size: 0;}
.dl06 { margin-bottom: 5px; width: 100%; overflow: hidden; }
.dl06 dt { width: 30%; float: left; -webkit-border-radius: 10px; border-radius: 10px; background: #ddd; padding: 0 10px; text-align: center; }
.dl06 dd { width: 70%; float: left; padding-left: 5px;}
.dl07 { border: 1px solid #ddd; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; background: #fff; padding: 0.625rem; min-height: 135px; }
.dl07 dt { font-size: 0.812rem !important; font-weight: normal; }
.dl07 dd { margin-top: 0.313rem; }
.dl08 dt { font-size: 0.812rem !important; font-weight: normal;}
.dl08 dd { margin-top: 0.313rem; }

.dl09 { width: 100%; overflow: hidden; }
.dl09 dt { width: 100px; float: left; text-align: center; border-Radius: 8px; background: #DDDDDD; margin-right: 10px}
.dl09 dd { float: left; }

/* background */
.bg01 { background: #fbfbfb; }
.bg02 { background: #eee; }


/* button */
.btn-area { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap;  }
.btn { display: inline-block; /* width: 100%; */ border-radius: 0.250rem; text-align: center; line-height: 3.000rem; font-size: 1.125rem; vertical-align: top; }
.btn.type01 { padding: 0 15px; background: #1255aa; color: #fff; border: 0 none; font-size: 0.875rem; }
.btn.type02 { font-weight: 600; font-size: 0.875rem; }
.btn.type03 { color: #23accc; font-weight: 600; font-size: 0.875rem; }
.btn.type04 { padding: 0 10px; background: #588ed4; color: #fff; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }
.btn.type05 { padding: 0 10px; background: #999; color: #fff; font-size: 0.875rem; line-height: 48px; }
.btn.type06 { color: #588ed4; font-size: 1rem; font-weight: 600; line-height: 1.25rem; }
.btn.type07 { padding: 0 10px; background: #fff; color: #588ed4; border: 1px solid #588ed4; font-size: 0.875rem; line-height: 2.5rem; }
.btn.type08 { padding: 0 10px; border: 1px solid #588ed4; color: #588ed4; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }
.btn.type09 { padding: 0 10px; background: #588ed4; color: #fff; font-size: 0.875rem; line-height: 50px; }
.btn.type10 { padding: 0 10px; border: 1px solid #23accc; color: #23accc; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; width: 100%; }
.btn.type11 { padding: 0 10px; background: #7ba0d0; color: #fff; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }
.btn.type12 { padding: 0 10px; background: #c2c2c2; color: #fff; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }
.btn.type13 { padding: 0 10px; background: #1255aa; color: #fff; font-size: 0.875rem; line-height: 48px; }
.btn.type14 { padding: 0 10px; background: #999; color: #fff; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }
.btn.type15 { padding: 0 15px; background: #fff; color: #588ed4; border: 1px solid #588ed4; line-height: 2.875rem; font-size: 0.875rem; }
.btn.type16 { padding: 0 15px; background: #588ed4; color: #fff; border: 0 none; font-size: 0.875rem;}
.btn.type17 { padding: 0 15px; background: #fff; color: #d50000; border: 1px solid#d50000; line-height: 2.875rem; font-size: 0.875rem; }
.btn.type18 { padding: 0 10px; background: #fff; color: #d50000; border: 1px solid#d50000; font-size: 0.875rem; line-height: 2.5rem; }
.btn.type19 { font-size: 1rem; line-height: 1.25rem; }
.btn.type20 { padding: 1px 8px; background: #fff; color: #333; border: 1px solid#ddd; line-height: 1.5rem; font-size: 0.840rem;}
.btn.type21 { padding: 0 10px; background: #000; color: #fff; border: 1px solid#000; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem;}
.btn.type22 { color: #588ed4; font-weight: 500; font-size: 0.875rem; line-height: 1.5rem; }
.btn.type23 { padding: 2px 10px; background: #7ba0d0; color: #fff; border: 0.15rem solid#7ba0d0; border-Radius: 6px; line-height: 1.5rem; font-size: 0.860rem;}
.btn.type24 { padding: 0 10px; background: #d50000; color: #fff; border: 1px solid#d50000; font-size: 0.875rem; line-height: 2.5rem; height: 2.5rem; }

.btn01 { width: 100%; border-radius: 0.250rem; text-align: center; font-size: 1rem; background: #1255aa; color: #fff; border: 2px solid #1255aa; line-height: 44px;}
.btn01.active { background: #1255aa; color: #fff; line-height: 3.000rem; border: 0 none; }

.btn.type04:hover {color : #0e0e0e;}
.btn.type07:hover { background: #588ed4; color: #fff; border: 1px solid #588ed4; }
.btn.type18:hover { background: #d50000; color: #fff; border: 1px solid #d50000; }
/* .btn-arrow01 { padding-top: 50px; width: 50px; font-size: 0; line-height: 0; overflow: hidden; background-image: url("/images/icon/arrow02_1.png"); background-repeat: no-repeat; background-position: center center; -webkit-background-size: 10px 10px; background-size: 10px 10px;} */
.btn-more01 { display: inline-block; padding: 0 10px; color: #aaa; font-size: 1.5rem; }
.btn-more01 svg { font-size: 1.5rem; }
.btn-more02 { display: inline-block; position: relative; color: #aaa; font-size: 0.75rem; }
.btn-search { padding: 0 10px; }

.btn-small { margin: 0 3px; font-size: 0.687rem !important; line-height: 1.5rem !important; padding: 0 7px !important; }

.wrap-foot-btn { padding: 0 10px; display: block; }
.c-sidebar-minimized .wrap-foot-btn { display: none; }


/* flex */
.row { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; margin: 0 -0.75rem; }
.d-flex { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }
.flex-space-between { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; align-items: center; -webkit-justify-content: space-between; justify-content: space-between; }
.flex-space-around { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; align-items: center; -webkit-justify-content: space-around; justify-content: space-around; }
.flex-align-center { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; align-items: center; }
.flex-justify-center { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-justify-content: center; justify-content: center; }
.flex-end { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; justify-content: flex-end; }
.flex-1 { -ms-flex: 1; -webkit-flex: 1; flex: 1; padding: 0 0.75rem; }
.flex-align-start {-webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; align-items: start; }
.flex-none { -ms-flex: none !important; -webkit-flex: none !important; flex: none !important; }


/* badge */
.badge { display: inline-block; }
.badge.type01 { padding: 0 5px; -webkit-border-radius: 10px; border-radius: 10px; border: 1px solid #f7504d; color: #f7504d; font-size: 0.75rem; }
.badge.type02 { color: #f7504d; font-size: 0.75rem !important; font-weight: 500; }

.badge01 { display: inline-block; -webkit-border-radius: 3px; border-radius: 3px; font-size: 0.75rem; padding: 3px; }
.badge01.type01 { background: #eee6ff; color: #7638ff; }
.badge01.type02 { background: #e0f5e6; color: #00b031; }
.badge01.type03 { background: #fceae9; color: #f45452 ; }
.badge01.type04 { background: #eceef2; color: #222; }
.badge01.type05 { background: #dce7fc; color: #6488fd; }
.badge01.type06 { background: #fff8f2; color: #ff9b61; }
.badge01.type07 { background: #feefff; color: #fa70ff; }


/* tab */
.tab-mn { /* display: flex; flex-wrap: wrap; */ position: relative; padding-left: 0; border-bottom: 1px solid #eee; }
.tab-mn::after { display: block; content: ""; clear: both; }
.tab-mn a { display: block; color: #b8b8b8; padding: 1.25rem 1.625rem; text-align: center; }
.tab-mn li { border-bottom: 0.250rem solid #f1f1f1; float: left; }
.tab-mn .on {  border-color: #588ed4; }
.tab-mn .on a { color: #588ed4; font-weight: 600; }

.tab-contents > li { display: none; }
.tab-contents > li.on { display: block; }

.wrap-tab.type02 .tab-mn { border-bottom: 0 none; }
.wrap-tab.type02 .tab-mn a { color: #333; }
.wrap-tab.type02 .tab-mn li { margin-left: -1px; border: 1px solid #ddd; }
.wrap-tab.type02 .tab-mn .on { background: #f2f9ff; }
.wrap-tab.type02 .tab-mn .on a { font-weight: 600; }


/* table */
.wrap-tb {width: 100%;}
.tb-list { border-top: 1px solid #000; word-break: keep-all; width: 100%;}
.tb-list th,
.tb-list td { border-bottom: 1px solid #ddd; padding: 10px; }

.tb-list.type02 { overflow-x: auto; }
.tb-list.type02 table { min-width: 1200px; overflow-x: auto; table-layout: fixed; }
.tb-list.type02 thead th { background: #f4f9ff; text-align: center; }
.tb-list.type02 th,
.tb-list.type02 td { border-left: 1px solid #ddd; }
.tb-list.type02 td a { text-decoration: underline; }
.tb-list.type02 th:first-child,
.tb-list.type02 td:first-child { border-left: 0 none }
.tb-list.type02 thead .txt02 { font-weight: normal; }
.tb-list.type02 tbody td { text-align: right; }

.tb-list.type03 { border-top: 2px solid #333; border-right: 1px solid #ddd; }
.tb-list.type03 th { background-color: #f9f9f9; }
.tb-list.type03 th,
.tb-list.type03 td { border-left: 1px solid #ddd; border-bottom: 1px solid #ddd;}

.tb-list.type04 { overflow-x: auto; }
.tb-list.type04 table { overflow-x: auto; table-layout: fixed; }
.tb-list.type04 thead th { background: #f4f9ff; text-align: center; }
.tb-list.type04 th,
.tb-list.type04 td { border-left: 1px solid #ddd; text-align: center; }
.tb-list.type04 td a { text-decoration: underline; }
.tb-list.type04 th:first-child,
.tb-list.type04 td:first-child { border-left: 0 none }
.tb-list.type04 thead .txt02 { font-weight: normal; }
.tb-list.type04 tbody td { text-align: center; }
.tb-list.type04 tfoot td { text-align: center; background: #f0f0f0; }

.tb-list.type05 { overflow-x: auto; }
.tb-list.type05 table { min-width: 600px; overflow-x: auto; table-layout: fixed; }
.tb-list.type05 thead th { background: #f4f9ff; text-align: center; }
.tb-list.type05 th,
.tb-list.type05 td { border-left: 1px solid #ddd; text-align: center; }
.tb-list.type05 td a { text-decoration: underline; }
.tb-list.type05 th:first-child,
.tb-list.type05 td:first-child { border-left: 0 none }
.tb-list.type05 thead .txt02 { font-weight: normal; }
.tb-list.type05 tbody td { text-align: center; }
.tb-list.type05 tfoot td { text-align: center; background: #f0f0f0; }

.view-tit { padding: 20px 0; border-top: 1px solid #000; font-size: 1.125rem !important; }
.view-info { padding-bottom: 20px; width: 100%; border-bottom: 1px solid #ddd; }
.view-info li { width: 50%; float: left; }
.view-info span { margin-right: 20px; line-height: 3.250em; }
.view-inner { padding: 20px 0; border-bottom: 1px solid #000; }


/* pager */
.pagination01 { text-align: center; }
.pagination01 ul { display: inline-flex; border: 1px solid #e2e6e9; -webkit-border-radius: 3px; border-radius: 3px; overflow: hidden; }
.pagination01 .page-items { border-left: 1px solid #e2e6e9; padding: 0.5rem 0.75rem; }
.pagination01 .page-items.active:hover { background: none; color: #333; }
.pagination01 .page-items:first-child { border-left: 0 none;}
.pagination01 button { display: block; margin: 0; color: #588ed4; }
.pagination01 .page-items:hover { background-color: #588ed4; color: #fff;}
.pagination01 .page-items:hover button { color: #fff;}
.pagination01 .disabled { color: #999; }
.pagination01 .disabled button { color: #999; }
.pagination01 .disabled:hover { color: #999; background: #fff; pointer-events: none; cursor: auto;}
.pagination01 .disabled:hover button { color: #999; }


/* form */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0; }
form { width: 100%; }
.form-list > li { margin-top: 10px; padding: 15px 0; border-top: 1px solid #f1f1f1; }
.form-list > li:first-child { margin-top: 0; border-top: 0 none; }
.form-group { position: relative; margin-bottom: 0; }
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="email"],
.form-group input[type="number"],
.form-group input[type="tel"],
.form-group input[type="phone"] { display: block; width: 100%; height: 50px; padding: 0 0.625rem;  border: 1px solid #e0e0e0; background-color: #fff; }
.form-group input::placeholder { color: #d0d0d0; }
.form-group textarea { display: block; width: 100%; height: 150px; padding: 0.625rem;  border: 1px solid #e0e0e0; background-color: #fff; }
.form-group textarea::placeholder { color: #d0d0d0; }
.form-group .form-radio { padding-right: 5px; float: left; }
.form-group .btn-area { width: 130px; float: right; }

.wrap-input { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; border: 1px solid #e0e0e0; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; padding: 0 0.75rem; background: #fff; }
.wrap-input .form-group { -ms-flex: 1 1 0%; -webkit-flex: 1 1 0%; flex: 1 1 0%; }
.wrap-input .form-group input { border: 0 none; }
.wrap-input .form-group-append { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }
.wrap-input textarea { border: 0 none; padding: 20px; }

.txtarea01 { min-height: 300px; }

.inp-txt { position: relative;  line-height: 52px; }
.inp-txt01 { justify-content: center; margin-bottom:20px;}

.msg { display: none; padding-top: 5px; color: #ff0000; font-size: 0.750rem; }
.msg.active { display: block; }

.form-radio { position: relative;  }
.form-radio-input { position: absolute; opacity: 0; }
.form-radio-label { display: block; cursor: pointer; min-height: 1.875rem; line-height: 1.875rem; }
.form-radio-sign::before,
.form-radio-sign::after {content: ""; width: 18px; height: 18px; -webkit-border-radius: 50%; border-radius: 50%; display: inline-block; position: absolute; left: 0px; top: 5px; }
.form-radio-label:hover { cursor: pointer; }
.form-radio .form-radio-label { padding-left: 25px;}
.form-radio .form-radio-sign::after {content: ""; width: 16px; height: 16px; -webkit-border-radius: 50%; border-radius: 50%; display: inline-block; position: absolute; left: 0px; top: 5px; border: 1px solid #dde1ed; background: #fff; }
.form-radio input[type="radio"]:checked + .form-radio-sign::before { background-color: #005AD4;}
.form-radio input[type="radio"]:checked + .form-radio-sign::after { width: 6px;height: 6px; top: 10px; left: 5px; background-color: #fff; border-color: #fff; opacity: 1;}

.wrap-radio.type01 { border: 1px solid #ddd; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; }
.wrap-radio.type01 label { border-left: 1px solid #ddd; position: relative; }
.wrap-radio.type01 label:first-child { border-left: 0 none; }
.wrap-radio.type01 input[type="radio"] { opacity: 0; position: absolute; top: 0; }
.wrap-radio.type01 em { display: block; padding: 0 10px; height: 100%; line-height: 48px; text-align: center; width: 100%; }/* 2020-10-07 */
.wrap-radio.type01 input[type="radio"]:checked + em { background-color: #c2ed9f; font-weight: bold; }

.form-check { position: relative; padding-left: 0 !important; }
.form-check-input { position: absolute; left: 0; z-index: -1; width: 1rem; height: 1.25rem; opacity: 0; }
.form-check-label { display: inline-block; position: relative; /* padding-left: 28px; */ width: 22px; height: 22px; cursor: pointer; }
.form-check-label.type02 { padding-left: 28px; width: auto; height: auto;}
.form-check-sign { position: relative; position: absolute; top: 0; left: 0; width: 22px; height: 22px;}
.form-check-sign::before { display: block; content: ""; position: absolute; top: 0; left: 0; width: 20px;height: 20px; pointer-events: none; background-color: #fff; border: 1px solid #adb5bd; }
.form-check-sign::after {display: block;content: ""; position: absolute; top: 0; left: 0; width: 22px; height: 22px; background-repeat: no-repeat; -webkit-background-size: 20px auto; background-size: 20px auto; background-position: center center; }
.form-check input[type="checkbox"]:checked + .form-check-sign::before { color: #fff; border-color: #007bff; background-color: #007bff;  }
/* .form-check input[type="checkbox"]:checked + .form-check-sign::after { background-image: url("/images/icon/arrow03.png"); } */
.chk-txt { display: inline-block; padding-left: 28px; line-height: 1.375rem; }

.selbox { padding-right: 0.938rem; border: 2px solid #588ed4; background: #fff; }
.selbox select { padding: 0.938rem 0 0.938rem 0.938rem; width: 100%; border: 0 none; color: #588ed4; background: #fff; }
.selbox.type02 { padding: 0 0.75rem; border: 1px solid #e0e0e0; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; }
.selbox.type02 select { /* padding: 0.625rem; */ padding: 0; color: #333; height: 48px; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; }

.file-attach { display: block; height: 100px; }
.file-attach input[type="file"] { visibility: hidden; position: absolute; left: -9999px; width: 0; height: 0; overflow: hidden; }
.btn-attach { display: table; width: 100%; height: 100%; text-align: center; cursor: pointer; }
.btn-attach span { display: table-cell; vertical-align: middle; color: #588ed4; }
.attach-name { display: block; }
.attach-name em { display: inline-block; margin-right: 3px; margin-top: 3px; padding: 3px 3px 3px 8px; line-height: 24px; border: 1px solid #ddd; -webkit-border-radius: 0.25rem; border-radius: 0.25rem; font-size: 0.687rem; }
.attach-name em i { cursor: pointer; margin-left: 3px; display: inline-block; padding: 0 5px; vertical-align: top; line-height: 24px; }
.file-attach.type02 { height: auto; }
.file-attach.type02 .btn-attach { display: block; }
.file-attach.type02 .btn-attach span { display: block; }

.form-email .form-group:nth-child(1) { width: 45%; }
.form-email .form-group:nth-child(2) { width: 20px; }
.form-email .form-group:nth-child(3) { width: 45%; }

.chk-switch { display: inline-block; position: relative; }
.chk-switch input[type="checkbox"] { position: absolute; top: 0; left: 0; z-index: -1; opacity: 0;}
.chk-switch em { display: inline-block; line-height: 20px; vertical-align: top; margin-right: 5px; }
.chk-switch span { display: inline-block; position: relative; -webkit-border-radius: 20px; border-radius: 20px; background-color: #999;  width: 32px; height: 20px; -webkit-transition: background-color 0.3s; transition: background-color 0.3s; vertical-align: top; }
.chk-switch span::after { display: block; content: ""; position: absolute; top: 1px; left: 1px; width: 18px; height: 18px; background: #fff; -webkit-border-radius: 10px; border-radius: 10px; -webkit-transition: left 0.3s; transition: left 0.3s; }
.chk-switch input[type="checkbox"]:checked + span { background-color: #1255aa; }
.chk-switch input[type="checkbox"]:checked + span::after { left: 13px; }

.wrap-search .wrap-input { display: inline-block; width: 60%; }
.wrap-search .btn { display: inline-block; width: 50px; }

.dust-class{
	margin:  10px auto;
    position: relative;
	width:100%;
}
.dust-class label{
	display: inline-block;
    position: absolute;
    top: -18px;
    left: -6px;
    padding: 10px;
    /* background: white; */
    font-size: 10px;
    color: #666;
    font-weight: bold;
}
.payment-group{
	width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
/* toggle */
.wrap-toggle .box-toggle { overflow: hidden; }
.wrap-toggle.on .box-toggle { height: auto !important; overflow: auto !important; }

.wrap-tooltip { position: relative; }
.tooltip { display: none; position: absolute; background: #1255aa; color: #fff; font-size: 0.687rem; padding: 5px; -webkit-border-radius: 5px; border-radius: 5px; min-width: 120px; }
.tooltip .fas { position: absolute; bottom: -15px; left: 20px; color: #1255aa; font-size: 1.75rem; }
.tooltip.on { display: inline-block; z-index: 10; }


/* popup */
.popup { display: none; position: fixed; left: 0; top: 0; width: 100%; height: 100%; align-items: center; justify-content: center; }
.popup::before { display: block; content: ""; position: fixed; width: 100%; height: 100%; background: #000; opacity: 0.6; }
.popup.on { -webkit-display: flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; z-index: 1000; }
.pop-inner { position: relative; max-width: 750px; min-width: 400px; -webkit-border-radius: 10px; border-radius: 10px; background: #fff; /* -webkit-box-shadow: 0 0 10px 0 #333;  box-shadow: 0 0 10px 0 #333; */ }
.pop-inner .form-group input[type="text"],
.pop-inner .form-group input[type="password"],
.pop-inner .form-group input[type="email"],
.pop-inner .form-group input[type="number"],
.pop-inner .form-group input[type="tel"],
.pop-inner .form-group input[type="phone"] { height: 40px; }
.pop-head { position: relative; padding: 30px 20px 20px; }
.pop-head h1 { font-size: 1.25rem; }
.pop-head .pop-close { position: absolute; top: 10px; right: 10px; color: #aaa; }
.pop-head .pop-open { position: absolute; top: 10px; right: 10px; color: #aaa; }
.pop-conents { padding: 10px 20px 30px; max-height: 700px; overflow-y: auto; }
.pop-footer { padding: 10px 20px; border-top: 1px solid #eee; }

.pop-dl01 { font-size: 14px; }
.pop-dl01 dt { font-weight: 600; margin-bottom: 10px;}
.pop-dl02 { font-size: 14px; width: 100%; overflow: hidden; }
.pop-dl02 dt { float: left; font-weight: 600; }
.pop-dl02 dd { margin-left: 10px; float: left; }

.pop-conents .btn.type05 { height: 40px; line-height: 40px; }

#pop-tip .pop-inner { width: 300px; }
#pop-holiday-noti .pop-inner { width: 300px; }
#pop-menu-group-add .pop-inner { width: 450px; }
#pop-menu-add .pop-inner { width: 530px; }
#pop-menu-info .pop-inner { width: 570px; }


/* reset */
.c-app { background-color: #fff; }

.pop-inner.type02 { max-width: 860px; width: 860px; }

.pop-float-type01 { width: 100%; overflow: hidden; }
.pop-float-type01 > div:first-child { width: 400px; float: left; }
.pop-float-type01 > div:first-child + div { width: 400px; float: right;}




@media (max-width: 1200px) {

	/* button */
	.btn-nav { position: absolute; top: 10px; left: 10px; padding: 10px; }
	.btn-nav .fas { font-size: 1.75rem; }

}

@media (max-width: 992px) {
	.tb td { font-size: 0.875rem; }
	.tb-list { overflow-x: auto; }
	.selbox.type02 { padding: 0; }
	.m-table { overflow-x: visible; }
	.m-table th,
	.m-table td { display: block; }

	.m-block { display: block; }
	.m-block > div { margin-top: 10px; }
	.m-block > div:first-child { margin-top: 0; }

	.m-hidden { display: none; }

	.m-visible { display: inline-block; }

	.m-flex-left { justify-content: left; }

	.wrap-sel-search .selbox { margin-left: 0 !important; }
	.wrap-sel-search .wrap-search { margin-right: 0 !important; padding: 0; }
	.wrap-sel-search .wrap-search .wrap-input { width: 75%; }

	.m-bottom-dash { border-bottom: 1px dashed #ddd; padding: 10px 0; }

	.pop-inner.type02 { max-width: 100%; width: 100%; }
	.pop-float-type01 > div { float: none !important; width: 100% !important; }


	/* space */
	.m-mt-5 { margin-top: 5px; }
}


@media (max-width: 768px) {
	.tab-mn a { padding: 0.625rem; }

	.wrap-input.w-50 { width: 100% !important; }

	.text-r { text-align: center; }
}


@media (max-width: 576px) {
	.txt04 { font-size: 1.375rem; }
	.pop-inner { max-width: 90%; min-width: 300px; margin: 0 auto; }
	.pop-conents { max-height: 450px; }
}
