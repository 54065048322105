@charset "utf-8";


#container { position: relative; }
#middle { padding: 1.250rem; }


/* aside */
#aside { position: fixed; top: 0; left: 0; width: 300px; height: 100vh; background-color: #343957; overflow-y: auto; z-index: 1000; -webkit-transition: left 0.1s linear 0.1s; transition: left 0.1s linear 0.1s; }
#nav { padding-right: 20px; }
#nav a { display: block; color: #fff; }
#m-aside { display: none; position: relative; }
/* .mn-toggle { padding: 0.75rem; color: #888 !important; background-image: url("/images/icon/arrow01_1.png"); background-repeat: no-repeat; background-position: right center; -webkit-background-size: 8px auto; background-size: 8px auto; } */
.nav-depth a { padding: 0.75rem; }
.nav-depth a:hover { color: #65a1d7 !important; }
.nav-mn .nav-depth { padding-left: 25px; display: none; }
.nav-mn .nav-depth .active a { background-color: #65a1d7; }
.nav-mn .nav-depth .active a:hover { color: #fff !important; }
.nav-mn.on .nav-depth { display: block;  }
/* .nav-mn.on .mn-toggle { background-image: url("/images/icon/arrow01_2.png"); -webkit-background-size: 13px auto; background-size: 13px auto; color: #fff !important; font-weight: bold; } */


/* location */
.location { margin-bottom: 30px; display: flex; flex-direction: row; justify-content: space-between; }
.location a { color: #333; }
.location button { color: #333; }
.location span { margin-left: 5px; }
.location .current { font-weight: bold; margin: auto 0; font-size : 1.1rem;}
.location Link { color: #333; }


/* contents */
#contents { padding: 40px 60px; height : 3000px}
/* #contents * { font-size: 0.875rem; } */
#contents.main * { font-size: inherit; }
.aside-owner-info { position: absolute; bottom: 130px; left: 0; width: 100%; text-align: center; }
.aside-owner-info a { color: #a3a3a3; font-size: 0.75rem; }
.aside-owner-info a em { color: #fff; font-weight: bold; }



@media (max-width: 1200px) {
	#aside { left: -350px; }
	#contents { padding: 50px; }
	#m-aside { display: block; padding: 20px 10px; }
	#m-aside h1 { text-align: center; font-weight: normal; font-size: 1.125rem; }
}


@media (max-width: 992px) {
	#contents { padding: 20px; }
}