@charset "utf-8";

.c-sidebar-nav { font-size: 0.875rem; }
.logo button { font-family: "Poppins", sans-serif; }
.logo Link { font-family: "Poppins", sans-serif; }
.c-sidebar-minimized .logo { display: none; }

.c-sidebar { background: #343957; }
.c-sidebar .c-sidebar-brand { background: #343957; }
.c-sidebar .c-sidebar-minimizer { background: #343957; }
.c-sidebar .c-sidebar-nav-dropdown.c-show { background: #1d244f; }
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover { background: #65a1d7; }
.c-sidebar .c-sidebar-nav-link.c-active { background: #65a1d7; }
.c-sidebar .c-sidebar-minimizer:hover { background-color: #343957; }

.container-fluid { padding: 0; margin: 0; }


.popup-overlay { z-index: 2000 !important; }
.popup-overlay::before { display: block; content: ""; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; opacity: 0.6; z-index: 2001;}
.popup-content { z-index: 2002; }


.c-header.c-header-fixed { z-index:1}
.css-mrh2d-ToastContainer { z-index:3000 !important; }
.react-toast-notifications__container { z-index:3000 !important; }